import React from 'react'
import Layout from 'components/Layout'
import Link from 'components/Link'
import SectionGetStarted from 'components/SectionGetStarted'
import CLI from 'components/cli';

class Homepage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      controls: null,
    }

    this.addControls = this.addControls.bind(this)
    this.removeControls = this.removeControls.bind(this)
  }

  addControls() {
    this.setState({ controls: 'controls' })
  }

  removeControls() {
    this.setState({ controls: null })
  }

  render() {
    return (
      <Layout
        title="Bluebase helps you make sense of your beacons"
        description="Use Bluebase to collect and store data from your IoT device fleet. Query, visualize and share your data to make your company data driven."
        location={this.props.location}
      >
        <section className="section section--hero">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h1 className="mb-xs">
                  Bluebase helps you make sense of your beacons
                </h1>
                <h3 className="font-light">
                  Connect and query events from your fleet of Bluetooth Low Energy beacons, build dashboards to
                  visualize data and share them with your company.
                </h3>
                <Link
                  data-track
                  data-track-location="hero"
                  track-event="Clicked Get Started"
                  to="https://app.bluebase.io/signup"
                  className="btn btn-primary btn-lg"
                >
                  Get Started
                </Link>
              </div>
              <div className="col-md-7 hidden-xs hidden-sm">
                <div className="browser-container hidden-xs">
                  <CLI/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section__feature section__feature--query">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <img
                  src="/assets/images/elements/write-queries.png"
                  id="write-query-illustrated"
                  className="visible-xs"
                />

                <h2>Bring Big Data to your beacons</h2>

                <p className="highlight-p">
                  Collect and manage every advertisement from your peripherals 
                  in a PostgreSQL database. Use our visualization tool or easily
                  export to a third party service.
                </p>

                <ul className="highlight-ul">
                  <li>Powerful online visualizatoins for beacon data</li>
                  <li>Browse and create new beacon schemas</li>
                  <li>Easy third party support</li>
                </ul>
              </div>
              <div className="col-sm-6 hidden-xs">
                <img
                  src="/assets/images/elements/write-queries.png"
                  id="write-query-illustrated"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section section__feature section__feature--visualize">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <img
                  src="/assets/images/elements/visualize-data.png"
                  id="visualize-illustrated"
                />
              </div>
              <div className="col-sm-6">
                <h2>Bring end-to-end testing to your beacons</h2>

                <p className="highlight-p">
                We harden your beacon fleet by running repeatable experiments 
                to test the battery life, signal strength, and data context 
                of your BLE peripherals.
                </p>

                <ul className="highlight-ul">
                  <li>Battery life testing</li>
                  <li>Signal strength and interference testing</li>
                  <li>Share test dashboards with your team or with the public</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="section section__more-features">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2 col-xs-12 p-0 flex-content-center flex">
                <div className="feature-container">
                  <img
                    src="/assets/images/elements/icon-api.png"
                    width="64px"
                  />
                  <h4>API</h4>
                </div>

                <div className="feature-container">
                  <img
                    src="/assets/images/elements/icon-alerts.png"
                    width="64px"
                  />
                  <h4>Alerts</h4>
                </div>

                <div className="feature-container">
                  <img
                    src="/assets/images/elements/icon-user-management.png"
                    width="64px"
                  />
                  <h4>CLI Tool</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-center">
                <br />
                <Link
                  data-track
                  data-track-location="features"
                  track-event="Clicked All Bluebase Features"
                  to="/"
                  className="btn btn-secondary"
                >
                  All Bluebase Features
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="section section__open-source">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-lg-offset-2 col-sm-5 col-sm-offset-1">
                <img
                  src="/assets/images/elements/open-source.png"
                  id="open-source-illustrated"
                  className="visible-xs"
                />
                <h2>Bluebase is an Open Source project</h2>
                <p className="highlight-p">
                  Customize and add features, no lock-in, contribute back to the
                  community
                </p>
                <Link
                  data-track
                  data-track-location="open-source"
                  track-event="Clicked Community"
                  to="https://github.com/bluebase-io/bluebase"
                  className="btn btn-secondary"
                >
                  Our Community
                </Link>
              </div>

              <div className="col-lg-4 col-sm-5 text-right hidden-xs">
                <img
                  src="/assets/images/elements/open-source.png"
                  id="open-source-illustrated"
                />
              </div>
            </div>
          </div>
        </section>

        <SectionGetStarted />
      </Layout>
    )
  }
}

export default Homepage
