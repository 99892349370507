import React from 'react';

const CLI = () => (
  <div className='CLI'>
    <div className='CLI__buttons'>
      <i className='CLI__button' />
      <i className='CLI__button' />
      <i className='CLI__button' />
    </div>
    <div className='CLI__header'>Bluebase CLI</div>

    <ul className='CLI__lines'>
      <li className='CLI__line'>$ npx bluebase-cli gateway --mac="ac:23:*"</li>
      <li className='CLI__line CLI__line--break' />
      <li className='CLI__line CLI__line--result'>⬢  Bluebase gateway pipe started (Hit Ctrl-C to stop)</li>
      <li className='CLI__line CLI__line--break' />
      <li className='CLI__line CLI__line--result'>
         gateway synced: <span className='CLI__line-time'>14:38:54 1337 ms</span>
      </li>
      <li className='CLI__line CLI__line--result'>
        &nbsp;data synced: <span className='CLI__line-time'>14:38:54</span>&nbsp;
        <span className='CLI__line-scope'>ac:23:df:0e:a5:84</span>&nbsp;
        <span className='CLI__line-diff'>3545 ms</span>&nbsp;
      </li>
      <li className='CLI__line CLI__line--result'>
        &nbsp;data synced: <span className='CLI__line-time'>14:38:59</span>&nbsp;
        <span className='CLI__line-scope'>ac:23:23:fb:fb:68</span>&nbsp;
        <span className='CLI__line-diff'>4144 ms</span>&nbsp;
      </li>
      <li className='CLI__line CLI__line--result'>
        &nbsp;data synced: <span className='CLI__line-time'>14:39:01</span>&nbsp;
        <span className='CLI__line-scope'>ac:23:eb:cb:d6:ec</span>&nbsp;
        <span className='CLI__line-diff'>4238 ms</span>&nbsp;
      </li>
      <li className='CLI__line CLI__line--result'>
        &nbsp;data synced: <span className='CLI__line-time'>14:39:01</span>&nbsp;
        <span className='CLI__line-scope'>ac:23:9b:e3:31:0f</span>&nbsp;
        <span className='CLI__line-diff'>4238 ms</span>&nbsp;
      </li>
      <li className='CLI__line CLI__line--result'>
        &nbsp;data synced: <span className='CLI__line-time'>14:39:01</span>&nbsp;
        <span className='CLI__line-scope'>ac:23:a4:d4:3b:43</span>&nbsp;
        <span className='CLI__line-diff'>2297 ms</span>&nbsp;
      </li>
      {/* TODO: Add animation */}
      <li className='CLI__line CLI__line--result'>scanning for BLE devices...</li>
    </ul>

    <style jsx>{`
      .CLI {
        text-align: left;
        border-radius: 6px;
        background-color: #0b0f15;
        box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.35);
        min-height: 398px;
        width: 100%;
        position: relative;
      }

      @media screen and (max-width: 560px) {
        .CLI {
          width: 100vw;
          left: calc(-50vw + 50%);
          box-shadow: none;
          min-height: 340px;
        }
      }

      .CLI__buttons {
        position: absolute;
        top: 12px;
        left: 12px;
      }

      .CLI__button {
        display: inline-block;
        vertical-align: top;
        margin-right: 4px;
        border-radius: 4px;
        width: 8px;
        height: 8px;
        background-color: #3e434a
      }

      .CLI__header {
        text-align: center;
        padding: 10px 0;
        font-size: 12px;
        color: #3e434a;
        font-weight: 500;
      }

      .CLI__lines {
        list-style: none;
        padding: 30px 40px 0;
        font-size: 14px;
        line-height: 20px;
        font-family: Monaco, Courier;
        font-weight: 600;
        letter-spacing: .1em;
        color: #b3bece;
      }

      @media screen and (max-width: 560px) {
        .CLI__lines {
          margin: 0 auto;
          display: table;
          font-weight: 100;
          letter-spacing: initial;
        }
      }

      @media screen and (min-width: 461px) and (max-width: 560px) {
        .CLI__lines {
          padding: 30px 16px 0;
          font-size: 14px;
        }
      }

      @media screen and (max-width: 460px) {
        .CLI__lines {
          padding: 30px 0 0;
          font-size: 10px;
        }
      }

      .CLI__line-scope {
        color: #b4e6fd;
      }

      .CLI__line-time {
        color: #dad9d7;
      }

      .CLI__line-diff {
        color: #757065;
      }

      .CLI__line--result {
        color: #8b8b8d;
      }

      .CLI__line--break {
        height: 20px;
      }

      .CLI__line--indent {
        margin-left: 12px;
      }
    `}</style>
  </div>
)

export default CLI
